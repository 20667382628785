<template>
  <div class="bg-white pb-20 m-2 rounded-lg border shadow-lg">
    <div class="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        <span class="block">Ready to move forward?</span>
        <span class="block">Get an instant quote or book today</span>
      </h2>
        <div class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
            <div class="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
            <router-link :to="{ name: 'Tinting'}" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-gray-200 sm:px-8" :class="`${bgbrand700} && hover:${bgbrand900}`">
                Instant Quote
            </router-link>
            <router-link :to="{ name: 'Booking' }" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white sm:px-8" :class="`${bgbrand500} hover:${bgbrand600}`">Booking</router-link>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import configSetup from '@/setup.js'

export default {
  setup() {
    const {brand, theme, bgbrand700, bgbrand900, bgbrand500, bgbrand600} = configSetup()

    return {brand, theme, bgbrand700, bgbrand900, bgbrand500, bgbrand600}
  }
  
}
</script>