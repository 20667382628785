
<template>
  <div class="relative bg-black">
    <div class="absolute inset-0">
      <img
        class="w-full h-full object-cover"
        :src="averyLink"
        alt="Avery Dennison"
      />
      <div
        class="absolute inset-0 bg-gray-600 mix-blend-multiply"
        aria-hidden="true"
      />
    </div>
    <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
      <h1
        class="
          text-4xl
          font-extrabold
          tracking-tight
          text-white
          sm:text-5xl
          lg:text-6xl
        "
      >
        Avery Dennison
        <span
          class="text-xs text-gray-900 px-2 py-1 rounded-3xl"
          :class="` ${bgbrand500}`"
          >Primary</span
        >
      </h1>
      <p class="mt-6 text-xl max-w-3xl" :class="`${textbrand100} `">
        {{ company }} uses Avery Dennison's "pre-ceramic" film which delivers
        better heat rejection against its competition.
      </p>
    </div>
  </div>
  <div class="max-w-7xl mx-auto py-24 px-4 bg-white sm:px-6 lg:px-8">
    <div
      class="mt-15 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8"
    >
      <div
        v-for="tier in averyDennson"
        :key="tier.name"
        class="
          relative
          p-8
          bg-white
          border border-gray-200
          rounded-2xl
          shadow-sm
          flex flex-col
        "
      >
        <div class="flex-1">
          <h3 class="text-xl font-semibold text-gray-900">
            {{ tier.name }}
            <span
              class="text-sm rounded bg-gray-600 py-1 px-2"
              :class="`${textbrand400}`"
              >{{ tier.warranty }} Warranty</span
            >
          </h3>
          <p class="mt-4 flex items-baseline text-gray-900">
            <span class="text-5xl font-extrabold tracking-tight">{{
              tier.vlt
            }}</span>
            <span class="ml-1 text-xl font-semibold">VLT</span>
          </p>
          <p class="mt-6 text-gray-500">Film Stats</p>
          <ul role="list" class="mt-6 space-y-6">
            <li class="flex">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6"
                :class="`${textbrand500}`"
                aria-hidden="true"
              />
              <span class="ml-3 text-gray-500"
                >Heat Rejection {{ tier.rejection }}</span
              >
            </li>
            <li class="flex">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6"
                :class="`${textbrand500}`"
                aria-hidden="true"
              />
              <span class="ml-3 text-gray-500"
                >UV Protection {{ tier.uv }}</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="relative bg-black">
    <div class="absolute inset-0">
      <img
        class="w-full h-full object-cover"
        :src="globalLink"
        alt="Global Window Films"
      />
      <div
        class="absolute inset-0 bg-gray-800 mix-blend-multiply"
        aria-hidden="true"
      />
    </div>
    <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
      <h1
        class="
          text-4xl
          font-extrabold
          tracking-tight
          text-white
          sm:text-5xl
          lg:text-6xl
        "
      >
        Global Window Films
        <span
          class="text-xs text-gray-900 px-2 py-1 rounded-3xl"
          :class="`${bgbrand500} `"
          >Secondary</span
        >
      </h1>
      <p class="mt-6 text-xl max-w-3xl" :class="`${textbrand100} `">
        A global manufacturer of premium automotive film for professionals.
      </p>
    </div>
  </div>
  <div class="max-w-7xl mx-auto py-24 px-4 bg-white sm:px-6 lg:px-8">
    <div
      class="mt-15 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8"
    >
      <div
        v-for="tier in globalWindowFilms"
        :key="tier.name"
        class="
          relative
          p-8
          bg-white
          border border-gray-200
          rounded-2xl
          shadow-sm
          flex flex-col
        "
      >
        <div class="flex-1">
          <h3 class="text-xl font-semibold text-gray-900">
            {{ tier.name }}
            <span
              class="text-sm rounded bg-gray-600 py-1 px-2"
              :class="`${textbrand400} `"
              >{{ tier.warranty }} Warranty</span
            >
          </h3>
          <p class="mt-4 flex items-baseline text-gray-900">
            <span class="text-5xl font-extrabold tracking-tight">{{
              tier.vlt
            }}</span>
            <span class="ml-1 text-xl font-semibold">VLT</span>
          </p>
          <p class="mt-6 text-gray-500">Film Stats</p>
          <ul role="list" class="mt-6 space-y-6">
            <li class="flex">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6"
                :class="`${textbrand500}`"
                aria-hidden="true"
              />
              <span class="ml-3 text-gray-500"
                >Heat Rejection {{ tier.rejection }}</span
              >
            </li>
            <li class="flex">
              <CheckIcon
                class="flex-shrink-0 w-6 h-6"
                :class="`${textbrand500}`"
                aria-hidden="true"
              />
              <span class="ml-3 text-gray-500"
                >UV Protection {{ tier.uv }}</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <CTA />
</template>

<script>
import { CheckIcon } from "@heroicons/vue/outline";
import CTA from "@/components/CTA";
import configSetup from "@/setup.js";

export default {
  created() {
    const { company } = configSetup();
    document.title = `${company} | Tint Options`;
  },

  setup() {
    const { 
      textbrand100,
      textbrand400,
      textbrand500,
      bgbrand500, 
      company 
      } = configSetup();

    const globalLink =
      "https://firebasestorage.googleapis.com/v0/b/s3-tint.appspot.com/o/Marketing%20Photos%2Fglobalwinoowfilms-logo.png?alt=media&token=286635e5-019b-4cdc-a2d3-7280507da309";
    const globalWindowFilms = [
      {
        name: "CHARCOAL QDP 5",
        vlt: "6%",
        rejection: "42%",
        uv: "99%",
        warranty: "Lifetime",
      },
      {
        name: "CHARCOAL QDP 20",
        vlt: "19%",
        rejection: "40%",
        uv: "99%",
        warranty: "Lifetime",
      },
      {
        name: "CHARCOAL QDP 30",
        vlt: "30%",
        rejection: "36%",
        uv: "99%",
        warranty: "Lifetime",
      },
    ];
    const averyLink =
      "https://firebasestorage.googleapis.com/v0/b/s3-tint.appspot.com/o/Marketing%20Photos%2FAvery_Dennison_logo_logotype.png?alt=media&token=3f99ee00-8b04-46d3-9b15-068e9c67c752";
    const averyDennson = [
      {
        name: "NR PRO 05",
        vlt: "6%",
        rejection: "57%",
        uv: "99%",
        warranty: "Lifetime",
      },
      {
        name: "NR PRO 20 ",
        vlt: "22%",
        rejection: "48%",
        uv: "99%",
        warranty: "Lifetime",
      },
      {
        name: "NR PRO 30",
        vlt: "32%",
        rejection: "43%",
        uv: "99%",
        warranty: "Lifetime",
      },
    ];
    return {
      globalWindowFilms,
      averyDennson,
      globalLink,
      averyLink,
      CTA,
      textbrand100,
      textbrand400,
      textbrand500,
      bgbrand500,
      company,
    };
  },

  components: { CheckIcon, CTA },
};
</script>